import React from 'react'
import "./Contact.css";
import Contactdata from './Contactdata';

function Contact() {
  return (
    <Contactdata/>
  )
}

export default Contact